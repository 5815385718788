import React from "react";
import "./nav.css";
import { useState } from "react";

function Nav() {
  const [showLinks, setshowLinks] = useState(false);
  const handleShowLinks = () => {
    setshowLinks(!showLinks);
  };
  return (
    <nav
      className={` navbar ${
        showLinks ? "show-nav" : "hide-nav"
      } md:invisible w-1/2 flex self-end `}
    >
      <ul className="navbar__links" onClick={handleShowLinks}>
        <li className="navbar__item slideInDown-1 pb-10">
          <a href="#projects" className="navbar__link">
            Projets
          </a>
        </li>
        <li className="navbar__item slideInDown-2">
          <a href="#contact" className="navbar__link">
            Me Contacter
          </a>
        </li>
      </ul>
      <button className="navbar__burger" onClick={handleShowLinks}>
        <span className="burger-bar"></span>
      </button>
    </nav>
  );
}
export default Nav;
