import * as React from "react";
import "./text-bloc.css";

const TextBloc = () => {
  return (
    <div className="flex items-center	">
      <div className="text-bloc mt-6 md:mt-0 md:space-y-12 pt-6 text-justify">
        <p>
          &nbsp;&nbsp;&nbsp;<strong>Développeur web</strong> basé à Bordeaux, je
          vous accompagne dès la rédaction du cahier des charges, la conception
          & jusqu'à la mise en ligne de votre <strong>projet</strong>.
          <br />
          <br />
          N'hésitez pas à me contacter afin que nous
          <strong>&nbsp;échangions</strong> plus longuement!
        </p>
      </div>
    </div>
  );
};

export default TextBloc;
