import ecoutez from "../gif/ecoutez-la-ville.gif";
import pop from "../gif/popmodels.gif";
import manakin from "../gif/manakin.gif";
import viatic from "../gif/viatic.gif";
import livementor from "../gif/livementor.gif";
import ungarsetunefille from "../gif/uguf.gif";
import epic from "../gif/epic.gif";

const contentCard = [
  {
    id: 1,
    title: "- Écoutez la ville -",
    text: "Création d'un site vitrine",
    textTech: "Vue Js & Node Js",
    href: "http://ecoutez-la-ville.fr/",
    src: ecoutez,
  },
  {
    id: 2,
    title: "- Pop Models -",
    text: "Création d'un site dynamique",
    textTech: "React Js & Strapi",
    href: "https://www.popmodels.fr/",
    src: pop,
  },
  {
    id: 3,
    title: "- Manakín -",
    text: "Création d'un site e-commerce",
    textTech: "Shopify: Liquid",
    href: "https://www.manakin-paris.fr/",
    src: manakin,
  },
  {
    id: 4,
    title: "- Viatic -",
    text: "Création d'un site dynamique",
    textTech: "Gatsby JS & Wordpress",
    href: "./",
    src: viatic,
  },
  {
    id: 5,
    title: "- LiveMentor -",
    text: "Intervention sur App existante",
    textTech: "Blocs additionnels Gutenberg",
    href: "https://www.livementor.com/",
    src: livementor,
  },
  {
    id: 6,
    title: "- Un Gars & Une Fille -",
    text: "Audit & accompagnement e-commerce",
    textTech: "Blocs additionnels Gutenberg",
    href: "https://boutique.ungarsetunefille.fr/",
    src: ungarsetunefille,
  },
  {
    id: 7,
    title: "- Epic Brasserie -",
    text: "Création d'un site e-commerce",
    textTech: "Shopify: Liquid",
    href: "https://epic-brasserie.fr/",
    src: epic,
  },
];

export default contentCard;
