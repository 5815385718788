import React from "react";
import "./projects-cards.css";
import contentCard from "../../assets/text/contentCard";
import "../buttons/button.css";

const Cards = () => {
  console.log(contentCard);
  return (
    <div id="projects">
      <h2 className="mt-12 mb-12 text-center text-3xl font-semibold">
        <span>Voici les projets sur lesquels je suis intervenu ⚡️</span>
      </h2>
      <div className="global-container">
        <div className="card-container flex-wrap h-full">
          {contentCard.map((object) => {
            return (
              <div
                id="card"
                key={object.id}
                className="flex flex-col	md:mr-4 mt-4 "
              >
                <img src={object.src} alt="project-visual" />
                <div id="content">
                  <h3 className="title-card">{object.title}</h3>

                  <p>
                    Mission:
                    <br />
                    {object.text}
                  </p>
                  <br />
                  <p>Technologie:</p>
                  {object.textTech}
                  <div id="visit-button">
                    <a
                      className="button button-2"
                      href={object.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visiter
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Cards;
