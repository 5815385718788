import React from "react";
import "./menu.css";

const Menu = () => {
  return (
    <ul className="menu_container">
      <li className="menu_items">
        <a href="#projects">Projets</a>
      </li>
      <li className="menu_items">
        <a href="#contact">Me contacter</a>
      </li>
    </ul>
  );
};

export default Menu;
