import React from "react";
import "./logo.css";
import avatar from "../../assets/images/gj.png";
import background from "../../assets/images/background5.jpg";
import depo from "../../assets/images/github.png";
import mail from "../../assets/images/email.png";
import social from "../../assets/images/linkedin.png";

const contentIcon = [
  {
    id: 1,
    name: "Github",
    href: "https://github.com/gregoryjuge",
    src: depo,
  },
  {
    id: 2,
    name: "Mail",
    href: "mailto:gregoryjuge5@gmail.com",
    src: mail,
  },
  {
    id: 3,
    name: "Linkedin",
    href: "https://www.linkedin.com/in/grégory-juge-83104890",
    src: social,
  },
];

const Team = () => {
  return (
    <span id="team">
      <div className="main-container flex md:flex-col">
        <div className="person">
          <div className="container">
            <div className="container-inner">
              <img className="circle" alt="circle-avatar" src={background} />

              <img className="img img2" alt="avatar" src={avatar} />
            </div>
          </div>
          {/* part of connect icon mail, github & linkedin */}
          <div className="divider"></div>
          <div className="name pb-4">Grégory Juge</div>
          <div className="logos flex justify-center space-x-6">
            {contentIcon.map((object, i) => {
              return (
                <a href={object.href} target="_blank" rel="noreferrer" key={i}>
                  <img
                    key={i}
                    alt={object.name}
                    className="h-6"
                    src={object.src}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </span>
  );
};

export default Team;
